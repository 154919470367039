import React from 'react';

import BannerManu from '../Components/BannerInfo02/BannerManutencao';
import Cabecalho from '../Components/header/Header';
import Produtos from '../Components/ProductList/ListaDeProdutos';
import Head from '../Components/seo/Seo';
import Footer from '../Components/footer/Rodape';


const ProdutosPage = () => {

    const InfoProdutos = {
        SeoTitle : 'Companhia da Vedação - Produtos',
        descriptionSeo: 'especializada em produtos para Vedação Hidráulica e serviços de manutenção industrial em Curitiba',
        TituloPage: 'Produtos',
        describePage: 'Peças nacionais e importadas de qualidade para vedação mecânica e industrial em Curitiba.',
    }

    return (
        <>
            <Head Titulo={`${InfoProdutos.SeoTitle}`} Descricao={`${InfoProdutos.descriptionSeo}`}/>
            <Cabecalho LinkName={'Produtos'}/>
            <BannerManu Titulo={`${InfoProdutos.TituloPage}`} Describe={`${InfoProdutos.describePage}`}/>
            <Produtos/>
            <Footer/>
        </>
    )
}

export default ProdutosPage;