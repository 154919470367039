import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { CardProduto } from './ProdutoCard';
import './produtos.scss';

import Catalogo from '../../images/CATALOGO.pdf'

const Produtos = () => {

    return (
        <section className='ProdutosLista-wrapper'>
            
            <div className='ProdutosLista-card-list-wrapper'>
                
                <CardProduto 
                    Nome={'Gaxetas'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/GAXETA.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Gaxetas"
                />
                <CardProduto 
                    Nome={'Retentores'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/RETENTOR.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Retentores"
                />
                <CardProduto 
                    Nome={'Raspadores'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/RASPADORES.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Raspadores"
                />
                <CardProduto 
                    Nome={'O-rings'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/ORING.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/O-rings"
                />
            </div>

            <div className='ProdutosLista-card-list-wrapper'>
                
                <CardProduto 
                    Nome={'Guias'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/GUIAS.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Guias"
                />
                <CardProduto 
                    Nome={'Linha PTFE'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/LINHAPTFE.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Linhas-PTFE"
                />
                <CardProduto 
                    Nome={'Back-UP'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/BACKUP.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Back-UP"
                />
                <CardProduto 
                    Nome={'Kits'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/KITS.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Kits"
                />
            </div>

            <div className='ProdutosLista-card-list-wrapper'>
                
                <CardProduto 
                    Nome={'BUFFER'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/BUFFER.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/Buffer"
                />
                <CardProduto 
                    Nome={'ZO E ZW'}
                    Imagem={ 
                        <StaticImage 
                            className='card-image' 
                            src="../../images/ZoeZW.png" 
                            placeholder='blurred' 
                            alt="Companhia da vedação - Soluções em vedações industriais"
                        />
                    }
                    Rota="/ZO-e-ZW"
                />

            </div>
            <div className='produto-botao-wrapper'>
                <a href={Catalogo} download="catalogo.pdf" alt="Catalogo Companhia da Vedação">
                    Baixar Catalogo em PDF
                </a>
            </div>

        </section>
    )
}

export default Produtos;