import React from 'react';
import './BannerManu.scss';

const BannerManu = ({Titulo, Describe}) => {

    return (
        <main className='bannerManu-wrapper'>
            <div className='bannerManu-text-wrapper'>
                <h2>{Titulo}</h2>
                <p>{Describe}</p>
            </div>
        </main>
    )
}

export default BannerManu;