import React from 'react';
import { Link } from 'gatsby';
import './produtos.scss';

export const CardProduto = ({Nome, Imagem, Rota}) => {

    return (
        <>
            <Link className='ProdutosLista-btn' to={Rota}>
                <div className='ProdutosLista-card-wrapper'>
                    {Imagem}
                    <h4>{Nome}</h4>
                </div>
            </Link>
        </>
    )
}